import {JsonAdapter} from "../helpers/JsonAdapter";

export class PrimaryBusinessUnit {
  id: string = "";
  name: string = "";
  business_sector_name: string = "";

  static create(object:any): PrimaryBusinessUnit {
    const item = JsonAdapter.fill(new PrimaryBusinessUnit(), object);

    return item;
  }

  static createMultiple(objects: PrimaryBusinessUnit[]): PrimaryBusinessUnit[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(PrimaryBusinessUnit.create(objects[i]));
    }
    return items;
  }
}
