import {JsonAdapter} from "../helpers/JsonAdapter";
import {Project} from "./Project";
import {Question} from "./Question";

export class ApplicationForm {
  id: string = "";
  application_form_id: string = "";
  description: string = "";
  project: Project[] = [];
  question: Question[] = [];
  created_at: string = "";

  static create(object:any): ApplicationForm {
    const item = JsonAdapter.fill(new ApplicationForm(), object);

    if(object.project) {
      item.project = Project.createMultiple(object.project);
    }

    if(object.question) {
      item.question = Question.createMultiple(object.question);
    }

    return item;
  }

  static createMultiple(objects: ApplicationForm[]): ApplicationForm[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(ApplicationForm.create(objects[i]));
    }
    return items;
  }
}
