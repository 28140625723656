import {JsonAdapter} from "../helpers/JsonAdapter";

export class BusinessSector {
  id: string = "";
  name: string = "";

  static create(object:any): BusinessSector {
    const item = JsonAdapter.fill(new BusinessSector(), object);

    return item;
  }

  static createMultiple(objects: BusinessSector[]): BusinessSector[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(BusinessSector.create(objects[i]));
    }
    return items;
  }
}
