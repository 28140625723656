import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {FormItemInterface} from "../../../interfaces/FormItemInterface";

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() element!: FormItemInterface<any[]>;
  @Input() form!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  get isInvalid() {
    const formItem = this.form.get(this.element.form);
    if(!formItem) {
      return false;
    }

    return formItem.invalid && (formItem.dirty || formItem.touched);
  }

  isClearable(clearable: any) {
    if(typeof clearable === "undefined")
      return true;

    return clearable;
  }

}
