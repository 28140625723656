import {JsonAdapter} from "../helpers/JsonAdapter";

export class Question {
  id: string = "";
  question_id: string = "";
  description: string = "";
  text: string = "";
  created_at: string = "";

  static create(object:any): Question {
    const item = JsonAdapter.fill(new Question(), object);

    return item;
  }

  static createMultiple(objects: Question[]): Question[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Question.create(objects[i]));
    }
    return items;
  }
}
