import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {InternalComponent} from "./shared/layout/internal/internal.component";
import {AuthGuard} from "./shared/auth/guards/auth.guard";

const routes: Routes = [
  {
    path: '',
    component: InternalComponent,
    children: [
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'people-partners',
        loadChildren: () => import('./people-partners/people-partners.module').then(m => m.PeoplePartnersModule)
      },
      {
        path: 'individuals',
        loadChildren: () => import('./individuals/individuals.module').then(m => m.IndividualsModule)
      },
      {
        path: 'vacancies',
        loadChildren: () => import('./vacancies/vacancies.module').then(m => m.VacanciesModule)
      },
      {
        path: 'vacancy-search',
        loadChildren: () => import('./vacancy-search/vacancy-search.module').then(m => m.VacancySearchModule)
      },
      {
        path: 'vacancy',
        loadChildren: () => import('./vacancy-details/vacancy-details.module').then(m => m.VacancyDetailsModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'applications',
        loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule)
      },
      {
        path: 'email-templates',
        loadChildren: () => import('./email-templates/email-templates.module').then(m => m.EmailTemplatesModule)
      },
      {
        path: 'account',
        loadChildren: () => import('./accounts/accounts.module').then(m => m.AccountsModule)
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },

  // redirect to home
  {
    path: '**',
    redirectTo: '/page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
