import {JsonAdapter} from "../helpers/JsonAdapter";
import {ApplicationForm} from "./ApplicationForm";

export class Project {
  id: string = "";
  name: string = "";
  description: string = "";
  created_at: string = "";
  application_form: ApplicationForm[] = [];

  static create(object:any): Project {
    const item = JsonAdapter.fill(new Project(), object);

    if(object.application_form) {
      item.application_form = ApplicationForm.createMultiple(object.application_form);
    }

    return item;
  }

  static createMultiple(objects: Project[]): Project[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Project.create(objects[i]));
    }
    return items;
  }
}
