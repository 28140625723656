import { NgModule } from '@angular/core';
import {NavigationSidebarComponent} from "../components/navigation-sidebar/navigation-sidebar.component";
import {AdminMenuComponent} from "../components/navigation-sidebar/admin-menu/admin-menu.component";
import {SharedModule} from "./shared.module";
import {PeoplePartnerMenuComponent} from "../components/navigation-sidebar/people-partner-menu/people-partner-menu.component";
import {IndividualMenuComponent} from "../components/navigation-sidebar/individual-menu/individual-menu.component";

@NgModule({
  declarations: [
    NavigationSidebarComponent,
    AdminMenuComponent,
    PeoplePartnerMenuComponent,
    IndividualMenuComponent,
  ],
  imports: [
    SharedModule
  ],
  exports: [
    NavigationSidebarComponent,
    AdminMenuComponent,
    PeoplePartnerMenuComponent,
    IndividualMenuComponent
  ]
})
export class NavigationModule { }
