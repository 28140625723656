<!-- Navigation -->
<ul class="navbar-nav">

  <li>
    <a routerLink="/vacancy-search" class="nav-link" routerLinkActive="active">
      <fa-icon [icon]="faBinoculars"></fa-icon>
      Advertised Vacancies
    </a>
  </li>
  <li>
    <a routerLink="/applications" class="nav-link" routerLinkActive="active">
      <fa-icon [icon]="faListCheck"></fa-icon>
      My Applications
    </a>
  </li>
</ul>
