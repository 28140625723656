import { Injectable } from '@angular/core';
import {ApplicationForm} from "../models/ApplicationForm";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {Answer} from "../models/Answer";

@Injectable({
  providedIn: 'root'
})
export class AnswerService {

  constructor(private http: HttpClient) { }

  add(answer: Answer): Observable<Answer> {
    return this.http.post<Answer>(environment.apiBaseUrl + 'answer', answer);
  }

  update(id: string, answer: Answer): Observable<Answer> {
    return this.http.put<Answer>(environment.apiBaseUrl + 'answer/' + id, answer);
  }
}
