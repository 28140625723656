import {Component, Input, OnInit} from '@angular/core';
import {Vacancy} from "../../../models/Vacancy";
import {Application} from "../../../models/Application";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormItemInterface} from "../../../interfaces/FormItemInterface";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {AnswerService} from "../../../services/answer.service";
import {ApplicationService} from "../../../../applications/services/application.service";
import {AuthService} from "../../../auth/services/auth.service";
import {FormHelper} from "../../../helpers/FormHelper";
import {forkJoin} from "rxjs";
import {BasicModalComponent} from "../basic-modal/basic-modal.component";
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-application-details-modal',
  templateUrl: './application-details-modal.component.html',
  styleUrls: ['./application-details-modal.component.scss']
})
export class ApplicationDetailsModalComponent implements OnInit {

  @Input() vacancy!: Vacancy;
  @Input() application!: Application;
  @Input() disable = false;

  faSpinner = faSpinner;
  form!: FormGroup;

  loading = {
    saving: false,
    querying: false
  }

  answer!: FormItemInterface<string>;
  currentRole!: FormItemInterface<string>;
  currentManager!: FormItemInterface<string>;

  constructor(private _fb: FormBuilder, public activeModal: NgbActiveModal,
              private answerService: AnswerService, private applicationService: ApplicationService, public authService: AuthService, private modal: NgbModal) {

    this.form = this._fb.group({
      answers: this._fb.array([]),
      current_role_and_area: ['', []],
      current_manager: ['', []]
    });

    this.answer = {
      form: 'answer',
      label: 'Answer'
    };

    this.currentRole = {
      form: 'current_role_and_area',
      label: 'Current Role and Area'
    };

    this.currentManager = {
      form: 'current_manager',
      label: 'Current Manager'
    };
  }

  ngOnInit() {

    this.vacancy.application_form.question.forEach(question => {
      const answer = this.form.get('answers') as FormArray;
      answer.push(this._fb.group({
        question: [question, [Validators.required]],
        answer: ['', [Validators.required]]
      }));
    });

    if(this.disable) {
      this.form.disable();
    }

    if(this.application) {
      this.form.patchValue({
        current_role_and_area: this.application.current_role_and_area,
        current_manager: this.application.current_manager,
        answers: this.application.answers
      });
    }
  }

  getAnswersAsFormArray(): FormArray {
    return this.form.get('answers') as FormArray;
  }

  getFormGroupFromArrayByIndex(i: number) {
    return this.getAnswersAsFormArray().at(i) as FormGroup;
  }

}
