import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternalComponent } from './shared/layout/internal/internal.component';
import {JwtModule} from "@auth0/angular-jwt";
import {AuthGuard} from "./shared/auth/guards/auth.guard";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {NavigationModule} from "./shared/modules/navigation.module";
import {QuillModule} from "ngx-quill";
import {config as QuillToolbarConfig} from "./shared/settings/QuillToolbar";
import { ApplicationFormModalComponent } from './shared/components/modals/application-form-modal/application-form-modal.component';
import {ReactiveFormsModule} from "@angular/forms";
import {FormElementsModule} from "./shared/modules/form-elements.module";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import { PromptModalComponent } from './shared/components/modals/prompt-modal/prompt-modal.component';
import { ApplicationDetailsModalComponent } from './shared/components/modals/application-details-modal/application-details-modal.component';
import {NavigationService} from "./shared/services/navigation.service";
import {ErrorInterceptorService} from "./shared/interceptors/error-interceptor.service";

export function tokenGetter() {
  return localStorage.getItem("access_token");
}

@NgModule({
  declarations: [
    AppComponent,
    InternalComponent,
    ApplicationFormModalComponent,
    PromptModalComponent,
    ApplicationDetailsModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["api.devel.talentview.org", "api.talentview.org", "api-dev.rolls-royce.talentview.co.uk", "api.rolls-royce.talentview.co.uk"]
      },
    }),
    NavigationModule,
    QuillModule.forRoot({
      modules: {
        toolbar: QuillToolbarConfig
      }
    }),
    FormElementsModule,
    FontAwesomeModule
  ],
  providers: [
    AuthGuard,
    NavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
