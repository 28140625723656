import {JsonAdapter} from "../helpers/JsonAdapter";
import {UserProfile} from "./UserProfile";

export class User {

  id: string = "";
  first_name: string = "";
  last_name: string = "";
  fullname: string = "";
  email: string = "";
  fake_email: boolean = false;
  roles: string[] = [];
  profile!: UserProfile;
  username: string = "";
  check_number: string = "";
  verified: boolean = false;
  active: boolean = false;

  static create(object:any): User {
    const item = JsonAdapter.fill(new User(), object);
    if(object.roles) {
      item.roles = object.roles;
    }

    if(object.profiles && object.profiles.length) {
      item.profile = UserProfile.create(object.profiles[0]);
      item.profile.roles = item.roles;
    } else if(object.roles && object.roles.includes("admin")) {
      item.profile = new UserProfile();
      item.profile.roles = item.roles;
    }

    return item;
  }

  static createMultiple(objects: User[]): User[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(User.create(objects[i]));
    }
    return items;
  }

  get name() {
    if(this.first_name && this.last_name) {
      return this.first_name + " " + this.last_name;
    } else {
      return this.fullname;
    }
  }
}
