import { NgModule } from '@angular/core';
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {HeaderComponent} from "../components/header/header.component";
import {ReactiveFormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {NgxPaginationModule} from "ngx-pagination";
import {BasicModalComponent} from "../components/modals/basic-modal/basic-modal.component";
import {DateTimeFormatPipe} from "../pipes/DateTimeFormatPipe";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  declarations: [
    HeaderComponent,
    BasicModalComponent,
    DateTimeFormatPipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    MatSnackBarModule,
    MatButtonModule
  ],
  exports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbModule,
    HeaderComponent,
    NgxPaginationModule,
    BasicModalComponent,
    DateTimeFormatPipe,
    MatSnackBarModule,
    MatButtonModule
  ]
})
export class SharedModule { }
