import {Pipe, PipeTransform} from '@angular/core';
import {DateTimeFormat} from "../helpers/DateTimeFormat";

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {
  constructor() {
  }

  transform(date: any, format: string | null = null): string {
    return DateTimeFormat.date(date, format);
  }
}
