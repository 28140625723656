import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss']
})
export class PromptModalComponent {

  @Input() itemName = "";
  @Input() title = "";
  @Input() confirmButton = "";
  constructor(public activeModal: NgbActiveModal) { }

}
