<!-- Navigation -->
<ul class="navbar-nav">
  <li>
    <a routerLink="/vacancy-search" class="nav-link" routerLinkActive="active">
      <fa-icon [icon]="faBinoculars"></fa-icon>
      Advertised Vacancies
    </a>
  </li>
</ul>

<!-- Divider -->
<hr class="navbar-divider my-3">

<!-- Heading -->
<h6 class="navbar-heading">
  Manage
</h6>

<ul class="navbar-nav">
  <li>
    <a routerLink="/vacancies" class="nav-link" routerLinkActive="active">
      <fa-icon [icon]="faBinoculars"></fa-icon>
      Vacancies
    </a>
  </li>
  <li>
    <a routerLink="/applications" class="nav-link" routerLinkActive="active">
      <fa-icon [icon]="faListCheck"></fa-icon>
      Applications
    </a>
  </li>
  <li class="nav-item">
    <a routerLink="/individuals" class="nav-link">
      <fa-icon [icon]="faPeople"></fa-icon> Employees
    </a>
  </li>
</ul>
