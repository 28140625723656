import { NgModule } from '@angular/core';
import {DropdownComponent} from "../components/form-elements/dropdown/dropdown.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {CommonModule} from "@angular/common";
import {ReactiveFormsModule} from "@angular/forms";
import {TextboxComponent} from "../components/form-elements/textbox/textbox.component";
import {HtmlTextareaComponent} from "../components/form-elements/html-textarea/html-textarea.component";
import {QuillModule} from "ngx-quill";
import {TextareaComponent} from "../components/form-elements/textarea/textarea.component";

@NgModule({
  declarations: [
    DropdownComponent,
    TextboxComponent,
    HtmlTextareaComponent,
    TextareaComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    QuillModule,
    NgSelectModule
  ],
  exports: [
    DropdownComponent,
    TextboxComponent,
    HtmlTextareaComponent,
    TextareaComponent,
    NgSelectModule
  ]
})
export class FormElementsModule { }
