import * as moment from 'moment';

export class DateTimeFormat {

    static date(date: any, format: string|null = null) {
        return moment(date).format(format || 'DD/MM/Y');
    }

    static dateTime(date: any) {
        return moment(date).format('Y. MM. DD HH:mm:ss');
    }

  static dateTimeWithoutSeconds(date: any) {
    return moment(date).format('Y. MM. DD. HH:mm');
  }
}
