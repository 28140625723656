<div class="modal-header">
  <h4 class="modal-title">Application Form</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <form [formGroup]="form" (submit)="save()">

    <!-- List group -->
    <div class="introduction" [innerHTML]="vacancy.application_form.description"></div>

    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-label no-input">Name</label>
          <div class="form-value-text">{{ authService.getCurrentUser()?.fullName }}</div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-label no-input">Check Number</label>
          <div class="form-value-text">{{ authService.getCurrentUser()?.check_number }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-label">Current Role and Area</label>
          <app-textbox [form]="form" [element]="currentRole"></app-textbox>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label class="form-label">Current Manager</label>
          <app-textbox [form]="form" [element]="currentManager"></app-textbox>
        </div>
      </div>
    </div>

    <div class="form-group" *ngFor="let question of vacancy.application_form.question; let i = index;">

      <!-- Label -->
      <label class="form-label" [innerHTML]="question.text"></label>

      <!-- Input -->
      <app-textbox [form]="getFormGroupFromArrayByIndex(i)" [element]="answer"></app-textbox>

    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  <button type="button" class="btn btn-primary" (click)="save()" *ngIf="!disable">Apply <fa-icon [icon]="faSpinner" [spin]="true" *ngIf="loading.saving"></fa-icon></button>
</div>
