<div class="modal-header">
  <h4 class="modal-title" id="modal-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <p><strong>Are you sure you want to delete <span class="text-primary">"{{ itemName }}"</span>?</strong></p>
  <p class="text-danger">All associated information will be permanently
    deleted and cannot be restored.
  </p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="activeModal.dismiss('cancel click')">Cancel</button>
  <button type="button" class="btn btn-danger" (click)="activeModal.close('Ok click')">{{ confirmButton || 'Delete' }}</button>
</div>
