import {JsonAdapter} from "../helpers/JsonAdapter";
import {JobFamilyGroup} from "./JobFamilyGroup";
import {Grade} from "./Grade";
import {SecondaryBusinessUnit} from "./SecondaryBusinessUnit";
import {PrimaryBusinessUnit} from "./PrimaryBusinessUnit";
import {BusinessSector} from "./BusinessSector";
import {SpecificSiteLocation} from "./SpecificSiteLocation";
import {SiteLocation} from "./SiteLocation";
import {environment} from "../../../environments/environment";
import {Organisation} from "./Organisation";
import {Project} from "./Project";
import {Observable} from "rxjs";
import { of } from 'rxjs';
import {ApplicationForm} from "./ApplicationForm";
import * as moment from "moment";

export class Vacancy {
  id: string = "";
  slug: string = "";
  job_title: string = "";
  job_summary: string = "";
  key_skills: string = "";
  additional_information: string = "";
  company_name: string = "";
  closing_date: string = "";
  created_at: string = "";
  job_family_group!: JobFamilyGroup;
  grade: Grade[] = [];
  site_location!: SiteLocation;
  specific_site_location!: SpecificSiteLocation;
  business_sector!: BusinessSector;
  primary_business_unit!: PrimaryBusinessUnit;
  secondary_business_unit!: SecondaryBusinessUnit;
  organisation!: Organisation;
  project!: Project;
  application_form!: ApplicationForm;
  status: string = "";
  reference_number: string = "";
  _current_user_meta!: any;
  job_comments: string = "";
  is_open: boolean = true;
  static DRAFT = 'draft';
  static COMPLETED = 'completed';
  static QUERIED = 'queried';
  static CONFIRMED = 'checked_and_confirmed';
  static ADVERTISED = 'advertised';

  static statuses = of([Vacancy.DRAFT, Vacancy.COMPLETED, Vacancy.QUERIED, Vacancy.CONFIRMED, Vacancy.ADVERTISED]);

  static create(object:any): Vacancy {
    const item = JsonAdapter.fill(new Vacancy(), object);

    if(object.organisation) {
      item.organisation = Organisation.create(object.organisation);
    }

    if (object.job_family_group) {
      item.job_family_group = JobFamilyGroup.create(object.job_family_group);
    }

    if(object.grade) {
      item.grade = Grade.createMultiple(object.grade);
    }

    if(object.site_location) {
      item.site_location = SiteLocation.create(object.site_location);
    }

    if(object.specific_site_location) {
      item.specific_site_location = SpecificSiteLocation.create(object.specific_site_location);
    }

    if(object.business_sector) {
      item.business_sector = BusinessSector.create(object.business_sector);
    }

    if(object.primary_business_unit) {
      item.primary_business_unit = PrimaryBusinessUnit.create(object.primary_business_unit);
    }

    if(object.secondary_business_unit) {
      item.secondary_business_unit = SecondaryBusinessUnit.create(object.secondary_business_unit);
    }

    if(object.project) {
      item.project = Project.create(object.project);
    }

    if(object.application_form) {
      item.application_form = ApplicationForm.create(object.application_form);
    }

    if(object._current_user_meta) {
      item._current_user_meta = object._current_user_meta;
    }

    if(!object.closing_date) {
      item.closing_date = null;
    }


    return item;
  }

  static createMultiple(objects: Vacancy[]): Vacancy[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Vacancy.create(objects[i]));
    }
    return items;
  }

  get organisation_logo_url() {
    return environment.apiBaseUrl + 'organisation/' + this.organisation.id + '/logo/current';
  }

  get location() {
    if(this.specific_site_location && this.specific_site_location.name) {
      return this.specific_site_location.name;
    }

    if (this.site_location && this.site_location.name) {
        return this.site_location.name;
    }

    return '';
  }

  get gradeListAsString() {
    const grade = [];
    if(this.grade) {

      for(let i=0; i<this.grade.length; i++) {
        grade.push(this.grade[i].name);
      }
    }

    return grade.join(', ');
  }

  get businessUnit() {
    const businessUnit = [];
    if(this.primary_business_unit) {
      businessUnit.push(this.primary_business_unit.name);
    }

    if(this.secondary_business_unit) {
      businessUnit.push(this.secondary_business_unit.name);
    }

    return businessUnit.join(', ');
  }

  isDraft() {
    return this.status === Vacancy.DRAFT;
  }

  isCompleted() {
    return this.status === Vacancy.COMPLETED;
  }

  isConfirmed() {
    return this.status === Vacancy.CONFIRMED;
  }

  isQueried() {
    return this.status === Vacancy.QUERIED;
  }

  getStatusDescription() {
    if(this.status === Vacancy.DRAFT) {
      return 'Draft';
    } else if(this.status === Vacancy.COMPLETED) {
        return 'Completed';
    } else if(this.status === Vacancy.QUERIED) {
      return 'Queried';
    } else if(this.status === Vacancy.CONFIRMED) {
      return 'Confirmed';
    } else if(this.status === Vacancy.ADVERTISED) {
      return 'Advertised';
    }

    return "";
  }

  isOpen() {
    return this.is_open;
  }

  isEditableByPeoplePartner(): boolean {
    return this.is_open && (this.isDraft() || this.isCompleted() || this.isQueried());
  }
}
