import {Component, Input, OnInit} from '@angular/core';
import {FormItemInterface} from "../../../interfaces/FormItemInterface";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {

  @Input() element!: FormItemInterface<string>;
  @Input() form!: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

  get isInvalid() {
    const formItem = this.form.get(this.element.form);
    if(!formItem) {
      return false;
    }

    return formItem.invalid && (formItem.dirty || formItem.touched);
  }

  get isRequired() {

    const formItem = this.form.get(this.element.form);
    if(!formItem) {
      return false;
    }

    return formItem.errors && formItem.errors['required'];
  }

  hasError(error: string) {

    const formItem = this.form.get(this.element.form);
    if(!formItem) {
      return false;
    }

    return formItem.errors && formItem.errors[error];
  }

}
