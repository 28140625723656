<ng-container [formGroup]="form">
  <ng-select
    *ngIf="element"
    [clearable]="isClearable(element.allowClear)"
    [items]="element.options | async"
    [formControlName]="element.form"
    [ngClass]="{'is-invalid': isInvalid}"
    [multiple]="element.multiple || false"
    [bindValue]="element.bindValue || null"
    [bindLabel]="element.bindLabel || 'name'"
    [addTag]="element.addTag || false"
    [appendTo]="'body'"
    [placeholder]="element.placeholder || ''"
  >
  </ng-select>
  <div class="invalid-feedback" *ngIf="isInvalid">Please provide a valid {{ element.label }}.</div>
</ng-container>
