import {JsonAdapter} from "../helpers/JsonAdapter";
import {Vacancy} from "./Vacancy";
import {Applicant} from "./Applicant";
import {Project} from "./Project";
import {ApplicationForm} from "./ApplicationForm";
import * as moment from "moment";

export class Application {
  id: string = "";
  vacancy!: Vacancy;
  organisation_name?: string = "";
  applicant?: Applicant;
  created_at: string = "";
  job_title: string = "";
  project!: Project;
  application_form!: ApplicationForm;
  current_role_and_area?: string = "";
  current_manager?: string = "";
  answers: any[] = [];
  priority: number = 0;

  static create(object:any): Application {
    const item = JsonAdapter.fill(new Application(), object);

    if(object.vacancy) {
      item.vacancy = Vacancy.create(object.vacancy);
    }

    if(object.applicant) {
      item.applicant = Applicant.create(object.applicant);
    }

    if(object.project) {
      item.project = Project.create(object.project);
    }

    if(object.application_form) {
      item.application_form = ApplicationForm.create(object.application_form);
    }

    if(object.answers) {
      item.answers = object.answers;
    }

    return item;
  }

  static createMultiple(objects: Application[]): Application[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Application.create(objects[i]));
    }
    return items;
  }

  get priorityLabel(): string {
    switch (this.priority) {
      case 1:
        return '1st priority';
      case 2:
        return '2nd priority';
      case 3:
        return '3rd priority';
      default:
        return 'Not set';
    }
  }

  isOpen() {
    return this.vacancy.isOpen();
  }
}
