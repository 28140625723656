import {JsonAdapter} from "../helpers/JsonAdapter";

export class JobFamilyGroup {
  id: string = "";
  name: string = "";

  static create(object:any): JobFamilyGroup {
    const item = JsonAdapter.fill(new JobFamilyGroup(), object);

    return item;
  }

  static createMultiple(objects: JobFamilyGroup[]): JobFamilyGroup[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(JobFamilyGroup.create(objects[i]));
    }
    return items;
  }
}
