import {JsonAdapter} from "../helpers/JsonAdapter";
import {Vacancy} from "./Vacancy";
import {User} from "./User";

export class Applicant {
  user!: User;
  check_number: string = "";
  organisation: String = "";

  static create(object:any): Applicant {
    const item = JsonAdapter.fill(new Applicant(), object);

    if(object.user) {
      item.user = User.create(object.user);
    }

    return item;
  }

  static createMultiple(objects: Applicant[]): Applicant[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Applicant.create(objects[i]));
    }
    return items;
  }

  get fullName() {
    return this.user.name;
  }
}
