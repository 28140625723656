import {JsonAdapter} from "../helpers/JsonAdapter";

export class SecondaryBusinessUnit {
  id: string = "";
  name: string = "";
  primary_business_unit_name: string = "";

  static create(object:any): SecondaryBusinessUnit {
    const item = JsonAdapter.fill(new SecondaryBusinessUnit(), object);

    return item;
  }

  static createMultiple(objects: SecondaryBusinessUnit[]): SecondaryBusinessUnit[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(SecondaryBusinessUnit.create(objects[i]));
    }
    return items;
  }
}
