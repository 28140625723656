import { Component, OnInit } from '@angular/core';
import {
  faBinoculars,
  faChartMixed, faChevronDown, faChevronUp, faClipboard, faEnvelope,
  faGauge,
  faListCheck,
  faPeople,
  faPeopleGroup
} from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {

  faPeopleGroup = faPeopleGroup;
  faListCheck = faListCheck;
  faPeople = faPeople;
  faBinoculars = faBinoculars;
  faGauge = faGauge;
  faChartMixed = faChartMixed;
  faClipboard = faClipboard;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faEnvelope = faEnvelope;

  isCollapsed = true;
  isIndividualCollapsed = true;
  isApplicationCollapsed = true;

  constructor() { }

  ngOnInit(): void {
  }

}
