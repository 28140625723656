import {JsonAdapter} from "../helpers/JsonAdapter";
import {User} from "./User";
import {Project} from "./Project";

export class UserProfile {
  id: string = "";
  user!: User;
  created_at: string = "";
  organisation_name: string = "";
  roles: string[] = [];
  check_number: string = "";
  user_last_login: string = "";
  project: Project[] = [];

  static create(object:any): UserProfile {
    const item = JsonAdapter.fill(new UserProfile(), object);
    if(object.user) {

      item.user = User.create(object.user);

    }

    if(object.project) {
      item.project = Project.createMultiple(object.project);
    }

    return item;
  }

  static createMultiple(objects: UserProfile[]): UserProfile[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(UserProfile.create(objects[i]));
    }
    return items;
  }

  get fullName() {
    if(!this.user) {
      return '';
    }

    return this.user.first_name + " " + this.user.last_name;
  }

  hasRole(role: string) {
    return this.roles.includes(role) || (this.user && this.user.roles.includes(role));
  }

  isIndividual() {
    return !this.isPeoplePartner() && !this.isAdmin();
  }

  isPeoplePartner() {
    return this.hasRole('company');
  }

  isAdmin() {
    return this.hasRole('admin');
  }

  get profileType() {
    if(this.isIndividual()) {
      return 'Employee';
    } else if(this.isPeoplePartner()) {
      return 'People Partner';
    } else if(this.isAdmin()) {
      return 'admin';
    }

    return 'Employee';
  }
}
