import {JsonAdapter} from "../helpers/JsonAdapter";

export class SpecificSiteLocation {
  id: string = "";
  name: string = "";
  site_location_name: string = "";

  static create(object:any): SpecificSiteLocation {
    const item = JsonAdapter.fill(new SpecificSiteLocation(), object);

    return item;
  }

  static createMultiple(objects: SpecificSiteLocation[]): SpecificSiteLocation[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(SpecificSiteLocation.create(objects[i]));
    }
    return items;
  }
}
