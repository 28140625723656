import {Injectable} from '@angular/core';
import {NavigationEnd, Event, NavigationError, Router, ActivatedRoute, NavigationStart} from "@angular/router";
import {BulkActionService} from "./bulk-action.service";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router, private route: ActivatedRoute, private bulkActionService: BulkActionService) {

    router.events.subscribe( (event: Event) => {

      if (event instanceof NavigationEnd) {
        this.navigationEnd(event);
      }

      if (event instanceof NavigationStart) {
        this.navigationStart(event);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
      }
    });
  }

  navigationEnd(event: NavigationEnd) {

  }

  navigationStart(event: NavigationStart) {
    this.bulkActionService.cancelBulkAction();
  }
}
