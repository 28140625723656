import { Component } from '@angular/core';
import {AuthService} from "./shared/auth/services/auth.service";
import {User} from "./shared/models/User";
import {NavigationService} from "./shared/services/navigation.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'rolls-royce-portal';


  constructor(private authService: AuthService, private navigationService: NavigationService) {

    if(this.authService.isLoggedIn()) {
      this.authService.fetchCurrentUserData().subscribe(
        (user: User) => {});
    }
  }
}
