import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {PaginatableInterface} from "../../shared/interfaces/PaginatableInterface";
import {Vacancy} from "../../shared/models/Vacancy";
import {environment} from "../../../environments/environment";
import {map} from "rxjs/operators";
import {Application} from "../../shared/models/Application";
import {Answer} from "../../shared/models/Answer";
import {ApplicationForm} from "../../shared/models/ApplicationForm";
import {Question} from "../../shared/models/Question";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private http: HttpClient) { }

  get(params: HttpParams): Observable<PaginatableInterface<Application[]>> {
    return this.http.get<PaginatableInterface<Application[]>>(environment.apiBaseUrl + 'application', {params: params}).pipe(
      map((result: PaginatableInterface<Application[]>) => {
        result.data = result.data.map(item => Application.create(item));
        return result;
      }));
  }

  getById(id: string, params: HttpParams): Observable<Application> {
    return this.http.get<Application>(environment.apiBaseUrl + 'application/' + id, {params: params}).pipe(
      map((result: Application) => {
        return Application.create(result);
      }));
  }

  add(application: any): Observable<Application> {
    return this.http.post<Application>(environment.apiBaseUrl + 'application', application);
  }

  update(id: string, application: any): Observable<Application> {
    return this.http.put<Application>(environment.apiBaseUrl + 'application/' + id, application);
  }

  delete(item: Application): Observable<Application> {
    return this.http.delete<Application>(environment.apiBaseUrl + 'application/' + item.id);
  }
}
