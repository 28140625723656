import { Component, OnInit } from '@angular/core';
import {faBell, faUser, faBinoculars, faChartMixed,
  faChevronDown,
  faClipboard, faGauge, faListCheck, faPeople, faPeopleGroup, faSearch, faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons';
import {AuthService} from "../../auth/services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: '[app-navigation-sidebar]',
  templateUrl: './navigation-sidebar.component.html',
  styleUrls: ['./navigation-sidebar.component.scss']
})
export class NavigationSidebarComponent implements OnInit {

  faUser = faUser;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faChevronDown = faChevronDown;

  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
  }

}
