<div class="container-fluid">

  <!-- Toggler -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <!-- Brand -->
  <a class="navbar-brand" routerLink="/vacancy-search">
    <img src="/assets/images/logos/Rolls_royce_holdings_logo.svg" class="navbar-brand-img
    mx-auto" alt="...">
  </a>

  <!-- User (xs) -->
  <div class="navbar-user d-md-none">

    <!-- Dropdown -->
    <div class="dropdown" ngbDropdown container="body">

      <!-- Toggle -->
      <a href="#" id="sidebarIcon" class="dropdown-toggle" ngbDropdownToggle role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div class="avatar avatar-sm avatar-online">
          <span class="icon">
            <fa-icon [icon]="faUser"></fa-icon>
          </span>
        </div>
      </a>

      <!-- Menu -->
      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="sidebarIcon">
        <a routerLink="/account" class="dropdown-item">Profile</a>
        <!--a href="@@webRoot/account-general.html" class="dropdown-item">Settings</a-->
        <hr class="dropdown-divider">
        <a href="@@webRoot/sign-in.html" class="dropdown-item">Logout</a>
      </div>

    </div>

  </div>

  <!-- Collapse -->
  <div class="collapse navbar-collapse" id="sidebarCollapse">

    <!-- Form -->
    <form class="mt-4 mb-3 d-md-none">
      <div class="input-group input-group-rounded input-group-merge">
        <input type="search" class="form-control form-control-rounded form-control-prepended" placeholder="Search" aria-label="Search">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <span class="fe fe-search"></span>
          </div>
        </div>
      </div>
    </form>

    <ng-container *ngIf="(authService.currentUser$ | async) as userProfile">
      <app-admin-menu *ngIf="userProfile.isAdmin()"></app-admin-menu>
      <app-people-partner-menu *ngIf="userProfile.isPeoplePartner()"></app-people-partner-menu>
      <app-individual-menu *ngIf="userProfile.isIndividual()"></app-individual-menu>


      <!-- Push content down -->
      <div class="mt-auto"></div>

      <!-- User (md) -->
      <div class="navbar-user d-none d-md-flex" id="sidebarUser">

        <!-- Icon -->
        <a routerLink="/account" class="navbar-user-link" data-toggle="modal" [ngbTooltip]="'Profile Settings'" *ngIf="!userProfile.isAdmin()">
          <span class="icon">
            <fa-icon [icon]="faUser"></fa-icon>
          </span>
        </a>

        <!-- Icon -->
        <a (click)="logout()" class="navbar-user-link" role="button" data-toggle="modal" [ngbTooltip]="'Log out'">
          <span class="icon">
            <fa-icon [icon]="faArrowRightFromBracket"></fa-icon>
          </span>
        </a>

      </div>

    </ng-container>

  </div> <!-- / .navbar-collapse -->

</div>
