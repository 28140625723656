import { Injectable } from '@angular/core';
import * as moment from "moment";
import {HttpClient} from "@angular/common/http";
import {User} from "../../models/User";
import {map, mergeMap, shareReplay, tap} from "rxjs/operators";
import {environment} from "../../../../environments/environment";
import jwt_decode from 'jwt-decode';
import {UserProfile} from "../../models/UserProfile";
import {BehaviorSubject, forkJoin, Observable} from "rxjs";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public currentUser$: BehaviorSubject<UserProfile|null> = new BehaviorSubject<UserProfile|null>(null);
  public currentUserId: string = "";
  public currentUserProfileId: string = "";

  constructor(private http: HttpClient, private router: Router) {

    // Try to renew the token in every 10 minutes if the remeaning time is after the half of this expiration time.
    setInterval(() => {
      this.renewToken();
    }, 600 * 1000);

  }

  login(credentials: {email: string, password: string}) {
    return this.http.post<User>(environment.apiBaseUrl + 'auth/login', credentials)
      .pipe(
        tap(res => {
          this.setSession(res);
        }),
        mergeMap(result1 => this.fetchCurrentUserData()),
      );
  }

  loginWithToken(token: string) {
    this.setSession(token);
    return this.fetchCurrentUserData();
  }

  private setSession(authResult: any) {
    const tokenData = this.getDecodedAccessToken(authResult.token);
    const expiresAt = moment(tokenData.exp * 1000);

    localStorage.setItem('access_token', authResult.token);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
  }

  logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("expires_at");

    this.currentUser$.next(null);
    this.currentUserId = "";
    this.currentUserProfileId = "";

    this.router.navigate(['/auth/sign-in']);
  }

  public getToken() {
    return localStorage.getItem('access_token');
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");

    if(!expiration)
      return moment().subtract('1 day');

    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    } catch(Error) {
      return null;
    }
  }


  // Logged In user data

  fetchCurrentUserData() {
    const accessToken = localStorage.getItem('access_token');

    if(accessToken) {
      const tokenData = this.getDecodedAccessToken(accessToken);
      const userProfileId = tokenData.id;

      this.currentUserId = userProfileId;
      if(tokenData.sub && tokenData.sub.profiles && tokenData.sub.profiles.length > 0) {
        this.currentUserProfileId = tokenData.sub.profiles[0];
      }

      return this.http.get<User>(environment.apiBaseUrl + 'user/' + userProfileId).pipe(
        tap(res => {
          this.refreshCurrentUser(res);
        })
      );
    }

    return Observable.create();
  }

  refreshCurrentUser(value: User) {
    this.currentUser$.next(User.create(value).profile);
  }

  getCurrentUser() {
    return this.currentUser$.getValue();
  }

  loginAs(userProfile: UserProfile) {
    const accessToken = localStorage.getItem('access_token');

    const credentials = {
      token: accessToken,
      userid: userProfile.user.id
    };

    return this.http.post<User>(environment.apiBaseUrl + 'auth/token', credentials)
      .pipe(
        tap(res => {
          this.setSession(res);
        }),
        mergeMap(result1 => this.fetchCurrentUserData()),
      );
  }

  renewToken() {

    if (!this.isLoggedIn()) {
      this.logout();
      return;
    }

    // check token if its half of the ttl we need to renew it
    const accessToken = localStorage.getItem('access_token');

    if(!accessToken) {
      this.logout();
      return;
    }

    const jwtData = this.getDecodedAccessToken(accessToken);
    const diff = jwtData.exp - jwtData.iat;
    const now = Math.floor(Date.now() / 1000);

    if (jwtData.exp - diff / 2 > now) {
      return;
    }

    this.http.post(environment.apiBaseUrl + 'auth/token', {token: accessToken})
      .subscribe(result => {
        this.setSession(result);
      }, error => {
        this.logout();
      });
  }

  hasRole(role: string) {
    return this.getCurrentUser()?.hasRole(role);
  }

  isIndividual() {
    return this.getCurrentUser()?.isIndividual();
  }

  isPeoplePartner() {
    return this.getCurrentUser()?.isPeoplePartner();
  }

  isAdmin() {
    return this.getCurrentUser()?.isAdmin();
  }

  getCurrentUserProfileId() {
    return this.currentUserProfileId;
  }

  getCurrentUserId() {
    return this.currentUserId;
  }
}
