import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Vacancy} from "../../../models/Vacancy";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {FormItemInterface} from "../../../interfaces/FormItemInterface";
import {FormHelper} from "../../../helpers/FormHelper";
import {AnswerService} from "../../../services/answer.service";
import {forkJoin} from "rxjs";
import {ApplicationService} from "../../../../applications/services/application.service";
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import {AuthService} from "../../../auth/services/auth.service";
import {Application} from "../../../models/Application";
import {BasicModalComponent} from "../basic-modal/basic-modal.component";

@Component({
  selector: 'app-application-form-modal',
  templateUrl: './application-form-modal.component.html',
  styleUrls: ['./application-form-modal.component.scss']
})
export class ApplicationFormModalComponent implements OnInit {

  @Input() vacancy!: Vacancy;
  @Input() application!: Application;
  @Input() disable = false;

  faSpinner = faSpinner;
  form!: FormGroup;

  loading = {
    saving: false,
    querying: false
  }

  answer!: FormItemInterface<string>;
  currentRole!: FormItemInterface<string>;
  currentManager!: FormItemInterface<string>;

  constructor(private _fb: FormBuilder, public activeModal: NgbActiveModal,
              private answerService: AnswerService, private applicationService: ApplicationService, public authService: AuthService, private modal: NgbModal) {

    this.form = this._fb.group({
      answers: this._fb.array([]),
      current_role_and_area: ['', []],
      current_manager: ['', []]
    });

    this.answer = {
      form: 'answer',
      label: 'Answer'
    };

    this.currentRole = {
      form: 'current_role_and_area',
      label: 'Current Role and Area'
    };

    this.currentManager = {
      form: 'current_manager',
      label: 'Current Manager'
    };
  }

  ngOnInit() {

    this.vacancy.application_form.question.forEach(question => {
      const answer = this.form.get('answers') as FormArray;
      answer.push(this._fb.group({
        question: [question, [Validators.required]],
        answer: ['', [Validators.required]]
      }));
    });

    if(this.disable) {
      this.form.disable();
    }

    if(this.application) {
      this.form.patchValue({
        current_role_and_area: this.application.current_role_and_area,
        current_manager: this.application.current_manager,
        answers: this.application.answers
      });
    }
  }

  getAnswersAsFormArray(): FormArray {
    return this.form.get('answers') as FormArray;
  }

  save() {
    FormHelper.markDirty(this.form);

    if (this.form.valid) {
      this.loading.saving = true;

      const answers = this.form.get('answers')?.value;
      const answers$ = answers.map((answer: any) => this.answerService.add(answer));

      forkJoin(answers$)
        .subscribe(result => {

          this.applicationService.add({
            applicant: {'id': this.authService.getCurrentUser()?.id},
            vacancy: {'id': this.vacancy.id},
            project: {'id': this.vacancy.project.id},
            application_form: this.vacancy.application_form,
            answers: result,
            current_role_and_area: this.form.get('current_role_and_area')?.value,
            current_manager: this.form.get('current_manager')?.value,
          }).subscribe(application => {
            this.activeModal.close(application);
            this.loading.saving = false;

              const modalRef = this.modal.open(BasicModalComponent);
              modalRef.componentInstance.name = '';
              modalRef.componentInstance.body = 'Thank you for your application. Please note, you can edit your application till closing date.';
          },
            error => {
            this.loading.saving = false;
          });
      }, error => {
        this.loading.saving = false;
      });
    }
  }

  getFormGroupFromArrayByIndex(i: number) {
    return this.getAnswersAsFormArray().at(i) as FormGroup;
  }
}
