import { Component, OnInit } from '@angular/core';
import {
  faBinoculars,
  faChartMixed, faChevronDown, faClipboard,
  faGauge,
  faListCheck,
  faPeople,
  faPeopleGroup
} from "@fortawesome/pro-light-svg-icons";

@Component({
  selector: 'app-people-partner-menu',
  templateUrl: './people-partner-menu.component.html',
  styleUrls: ['./people-partner-menu.component.scss']
})
export class PeoplePartnerMenuComponent implements OnInit {

  faPeopleGroup = faPeopleGroup;
  faListCheck = faListCheck;
  faPeople = faPeople;
  faBinoculars = faBinoculars;
  faGauge = faGauge;
  faChartMixed = faChartMixed;
  faClipboard = faClipboard;
  faChevronDown = faChevronDown;

  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

}
