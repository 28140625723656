import {JsonAdapter} from "../helpers/JsonAdapter";

export class Organisation {
  id: string = "";
  name: String = "";

  static create(object:any): Organisation {
    const item = JsonAdapter.fill(new Organisation(), object);

    return item;
  }

  static createMultiple(objects: Organisation[]): Organisation[] {
    const items = [];
    for (let i = 0; i < objects.length; i++) {
      items.push(Organisation.create(objects[i]));
    }
    return items;
  }
}
