import { Injectable } from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
  providedIn: 'root'
})
export class BulkActionService {

  snackBarRef: any;
  private lastAction: any = null;

  constructor(private _snackBar: MatSnackBar) { }

  showBulkAction(length: number, action: () => void, actionButtonLabel: string) {
    this.snackBarRef = this._snackBar.open('Selected ' + length + ' item(s)', actionButtonLabel);
    this.snackBarRef.onAction().subscribe(() => {
      action();
    });
  }

  showBulkActionFromComponent(length: number, action: (status: string) => void, actionButtonLabel: string, component: any) {
    this.snackBarRef = this._snackBar.openFromComponent(component, {data: {actionButtonLabel: actionButtonLabel, snackLabel: 'Selected ' + length + ' item(s)'}});
    this.snackBarRef.onAction().subscribe((status: any) => {
      action(this.getLastAction());
    });
  }

  cancelBulkAction() {
    if(this.snackBarRef) {
      this.snackBarRef.dismiss();
    }
  }

  getLastAction() {
    return this.lastAction;
  }

  setLastAction(status: string) {
    this.lastAction = status;
  }
}
